import React from 'react';
import IntlTelInput, {CountryData} from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import VariantsEnum from '@/enums/VariantsEnum';
import Error from '@/UI/form-control/error';
import styles from './phone.module.css';

type IProps = {
    id?: string
    name: string
    label: string
    value?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    error?: string
    onlyCountries?: string[]
    defaultCountry?: string
    className?: string
    onChange?: (number: string, fullNumber: string, isValid: boolean) => void
    onBlur?: (number: string, fullNumber: string, isValid: boolean) => void
}

const Input: React.FC<IProps> = ({
    id,
    name,
    label,
    value = '',
    placeholder,
    required,
    disabled,
    error,
    onlyCountries = ['cz', 'gb'],
    defaultCountry = 'cz',
    className = '',
    onChange,
    onBlur,
}) => {
    const handleChange = (valid: boolean, number: string, _: CountryData, fullNumber: string) => {
        if (onChange) {
            onChange(number, fullNumber, valid);
        }
    };
    const handleBlur = (valid: boolean, number: string, _: CountryData, fullNumber: string) => {
        if (onBlur) {
            onBlur(number, fullNumber, valid);
        }
    };

    return (
        <div className={`${styles.control} ${disabled ? styles.disabled : ''} ${error ? styles[VariantsEnum.danger] : ''} ${className}`}>
            {required && (<sup className={styles.required}>*</sup>)}
            <IntlTelInput
                formatOnInit
                fieldName={name}
                fieldId={id || name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                containerClassName={`${styles.container} intl-tel-input`}
                inputClassName={styles.input}
                defaultCountry={defaultCountry}
                onlyCountries={onlyCountries}
                preferredCountries={[]}
                aria-errormessage={`err_${id || name}`}
                aria-invalid={value && error}
                onPhoneNumberChange={handleChange}
                onPhoneNumberBlur={handleBlur}
            />
            <label
                htmlFor={id || name}
                className={styles.label}
            >
                <span className={styles.labelContent}>
                    {label}
                </span>
            </label>

            {error && <Error id={`err_${id || name}`}>{error}</Error>}
        </div>
    );
};

Input.displayName = 'Input';

export default Input;
